import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./style.css";
export default function Footer() {
  return (
    <React.Fragment>
      <footer>
        <CustomLink
          to="/de/impressum/"
          title="Impressum"
          style={{
            display: "inline-block",
          }}
        >
          Impressum
        </CustomLink>
        <CustomLink
          to="/de/datenschutz/"
          title="Datenschutz"
          style={{
            display: "inline-block",
          }}
        >
          Datenschutz
        </CustomLink>
      </footer>
    </React.Fragment>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
