import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";


import Analatics from "./components/analatics"

const Home = lazy(() => import("./pages/Home"));
const Aktuelles = lazy(() => import("./pages/Aktuelles"));
// const Team = lazy(() => import("./pages/Team"));
const Sprechzeiten = lazy(() => import("./pages/Sprechzeiten"));
const Anfahrt = lazy(() => import("./pages/Anfahrt"));
//
const SprechzeitenV2 = lazy(() => import("./pages/Sprechzeiten/indexV2"));
const AnfahrtV2 = lazy(() => import("./pages/Anfahrt/indexV2"));
//
const Karriere = lazy(() => import("./pages/Karriere"));
const Arbeitsunfälle = lazy(() => import("./pages/Arbeitsunfälle"));
//
const Kniegelenk = lazy(() => import("./pages/Behandlungsspektrum/Kniegelenk"));
const Sprunggelenk = lazy(() =>
  import("./pages/Behandlungsspektrum/Sprunggelenk")
);
const Fuss = lazy(() => import("./pages/Behandlungsspektrum/Fuss"));
const Knorpel = lazy(() => import("./pages/Behandlungsspektrum/Knorpel"));
const Hand = lazy(() => import("./pages/Behandlungsspektrum/Hand"));
const Ellenbogen = lazy(() => import("./pages/Behandlungsspektrum/Ellenbogen"));
const Schulter = lazy(() => import("./pages/Behandlungsspektrum/Schulter"));
//
const Sportmedizin = lazy(() => import("./pages/Sportmedizin"));
const Impressum = lazy(() => import("./pages/Impressum"));
const Datenschutz = lazy(() => import("./pages/Datenschutz"));
// 404
const NotFound = lazy(() => import("./pages/404"));




function App() {
  return (
    <React.Fragment>
      <Analatics />
      <div className="App">
        <Sidebar />
        <main>
          <Suspense fallback={<div className="loading loading-lg"></div>}>
            <Routes>
              <Route path='*' element={<NotFound />} />

              <Route path="/" element={<Navigate to="/de/" />} />
              <Route path="/de/" element={<Home />} />
              <Route path="/de/aktuelles/" element={<Aktuelles />} />

              <Route path="/de/bruchsal/team/" element={<Navigate to="/de/" />} />
              {/* <Route path="/de/bruchsal/team/" element={<Team />} /> */}
              <Route
                path="/de/bruchsal/sprechzeiten/"
                element={<Sprechzeiten />}
              />
              <Route path="/de/bruchsal/anfahrt/" element={<Anfahrt />} />
              {/*  */}
              <Route
                path="/de/bretten/sprechzeiten/"
                element={<SprechzeitenV2 />}
              />
              <Route path="/de/bretten/anfahrt/" element={<AnfahrtV2 />} />
              {/*  */}
              <Route path="/de/karriere/" element={<Karriere />} />
              <Route path="/de/arbeitsunfälle/" element={<Arbeitsunfälle />} />
              {/*  */}
              <Route
                path="/de/behandlungsspektrum/kniegelenk/"
                element={<Kniegelenk />}
              />
              <Route
                path="/de/behandlungsspektrum/sprunggelenk/"
                element={<Sprunggelenk />}
              />
              <Route path="/de/behandlungsspektrum/fuss/" element={<Fuss />} />
              <Route
                path="/de/behandlungsspektrum/knorpel/"
                element={<Knorpel />}
              />
              <Route path="/de/behandlungsspektrum/hand/" element={<Hand />} />
              <Route
                path="/de/behandlungsspektrum/ellenbogen/"
                element={<Ellenbogen />}
              />
              <Route
                path="/de/behandlungsspektrum/schulter/"
                element={<Schulter />}
              />
              {/*  */}
              <Route path="/de/sportmedizin/" element={<Sportmedizin />} />
              <Route path="/de/impressum/" element={<Impressum />} />
              <Route path="/de/datenschutz/" element={<Datenschutz />} />
            </Routes>
          </Suspense>
          <Footer />
        </main>
      </div>
    </React.Fragment>
  );
}

export default App;
