import React, { useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import "./sidebar.css";
import Logo from "../../images/logo.png";

import Address from "../../images/contacts/iconmonstr-mail-thin.svg";
import Tel from "../../images/contacts/iconmonstr-phone-1.svg";
import Fax from "../../images/contacts/iconmonstr-fax-8.svg";
import Email from "../../images/contacts/iconmonstr-email-12.svg";

import Menu from "../../images/Menu.svg";
import Menuclose from "../../images/Close.svg";

export default function Sidebar() {
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled(!isToggled);

  // Expander
  const details = document.querySelectorAll("details");
  details.forEach((detail) => {
    detail.addEventListener("toggle", () => {
      if (detail.open) setTargetDetail(detail);
    });
  });
  function setTargetDetail(targetDetail) {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.open = false;
      }
    });
  }
  //

  const location = useLocation();
  const [page, setPost] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://p665936.mittwaldserver.info/Sportorthopadie/api/singletons/get/kontakt?token=8933bdb58e3f0add45c7ad02330dbf",
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setPost(response);
        //console.log(response);
      });
    };
    fetchData();
    //
  }, []);

  if (!page) return null;

  return (
    <React.Fragment>
      <header className="App-header">
        {!isToggled && (
          <button className="Menu" onClick={toggle}>
            <img
              className="site-menu"
              width="260"
              height="108"
              src={Menu}
              alt="Sportorthopaedie Bruchsal | Menu"
              title="Sportorthopaedie Bruchsal | Menu"
            />
          </button>
        )}
        {isToggled && (
          <button className="Menu" onClick={toggle}>
            <img
              className="site-menu"
              width="260"
              height="108"
              src={Menuclose}
              alt="Sportorthopaedie Bruchsal | Menu"
              title="Sportorthopaedie Bruchsal | Menu"
            />
          </button>
        )}

        <div className="MenuHolder">
          {/*  */}
          <Link
            to={`/de/`}
            className="site-link"
            alt="Sportorthopaedie Bruchsal | DE"
            title="Sportorthopaedie Bruchsal | DE"
          >
            <img
              className="site-logo-loading"
              width="260"
              height="108"
              src={Logo}
              alt="Sportorthopaedie Bruchsal | DE"
              title="Sportorthopaedie Bruchsal | DE"
            />
          </Link>
          {/*  */}
          <div
            className={"MenuHolderExpander " + (isToggled ? "show" : "hidden")}
          >
            <CustomLink
              to="/de/aktuelles/"
              title="Aktuelles"
              style={{
                display: "inline-block",
              }}
            >
              Aktuelles
            </CustomLink>
            {/*  */}
            <details>
              <summary
                className={
                  location.pathname.split("/")[2] === "bruchsal"
                    ? "site-link-active"
                    : ""
                }
                title="Bruchsal"
              >
                Bruchsal
              </summary>

              {/* <CustomLink
                to="/de/bruchsal/team/"
                title="Team"
                style={{
                  display: "inline-block",
                }}
              >
                Team
              </CustomLink> */}
              <CustomLink
                to="/de/bruchsal/sprechzeiten/"
                title="Sprechzeiten"
                style={{
                  display: "inline-block",
                }}
              >
                Sprechzeiten
              </CustomLink>
              <CustomLink
                to="/de/bruchsal/anfahrt/"
                title="Anfahrt"
                style={{
                  display: "inline-block",
                }}
              >
                Anfahrt
              </CustomLink>
            </details>
            {/*  */}
            <details>
              <summary
                className={
                  location.pathname.split("/")[2] === "bretten"
                    ? "site-link-active"
                    : ""
                }
                title="Bretten"
              >
                Bretten
              </summary>

              <CustomLink
                to="/de/bretten/sprechzeiten/"
                title="Sprechzeiten"
                style={{
                  display: "inline-block",
                }}
              >
                Sprechzeiten
              </CustomLink>
              <CustomLink
                to="/de/bretten/anfahrt/"
                title="Anfahrt"
                style={{
                  display: "inline-block",
                }}
              >
                Anfahrt
              </CustomLink>
            </details>
            {/*  */}
            <CustomLink
              to="/de/karriere/"
              title="Karriere"
              style={{
                display: "inline-block",
              }}
            >
              Karriere
            </CustomLink>

            <CustomLink
              to="/de/arbeitsunfälle/"
              title="Arbeitsunfälle"
              style={{
                display: "inline-block",
              }}
            >
              Arbeitsunfälle
            </CustomLink>

            {/*  */}
            <details>
              <summary
                className={
                  location.pathname.split("/")[2] === "behandlungsspektrum"
                    ? "site-link-active"
                    : ""
                }
                title="Behandlungsspektrum"
              >
                Behandlungsspektrum
              </summary>

              <CustomLink
                to="/de/behandlungsspektrum/kniegelenk/"
                title="kniegelenk"
                style={{
                  display: "inline-block",
                }}
              >
                Kniegelenk
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/sprunggelenk/"
                title="Sprunggelenk"
                style={{
                  display: "inline-block",
                }}
              >
                Sprunggelenk
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/fuss/"
                title="Fuss"
                style={{
                  display: "inline-block",
                }}
              >
                Fuss
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/knorpel/"
                title="Knorpel"
                style={{
                  display: "inline-block",
                }}
              >
                Knorpel
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/hand/"
                title="Hand"
                style={{
                  display: "inline-block",
                }}
              >
                Hand
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/ellenbogen/"
                title="Ellenbogen"
                style={{
                  display: "inline-block",
                }}
              >
                Ellenbogen
              </CustomLink>
              <CustomLink
                to="/de/behandlungsspektrum/schulter/"
                title="Schulter"
                style={{
                  display: "inline-block",
                }}
              >
                Schulter
              </CustomLink>
            </details>
            {/*  */}
            <CustomLink
              to="/de/sportmedizin/"
              title="Sportmedizin"
              style={{
                display: "inline-block",
              }}
            >
              Sportmedizin
            </CustomLink>


          </div>

          {/*  */}
        </div>
        {/*  */}
        <div className="ContactBar">
          <h2>{page.title}</h2>
          <div
            className="contacts"
            style={{
              marginBottom: "15px",
            }}
          >
            <div className="Left">
              <img
                className="site-contact-imgs"
                width="25"
                height="25"
                src={Address}
                alt={page.contacts_address}
                title={page.contacts_address}
              />
            </div>
            <div className="Right">{page.contacts_address}</div>
          </div>
          {/*  */}
          <div className="contacts">
            {page.contacts_tel &&
              page.contacts_tel.map((item, index) => (
                <div className="contacts" key={index}>
                  <div className="Left">
                    <img
                      className="site-contact-imgs"
                      width="25"
                      height="25"
                      src={Tel}
                      alt={item.value.title}
                      title={item.value.title}
                    />
                  </div>
                  <div className="Right">
                    <a href={`tel:` + item.value.title}>
                      {item.value.title}
                      {"  "}
                      {item.value.name}
                    </a>
                  </div>
                </div>
              ))}
          </div>
          {/*  */}
          <div className="contacts">
            <div className="Left">
              <img
                className="site-contact-imgs"
                width="25"
                height="25"
                src={Fax}
                alt={page.contacts_fax}
                title={page.contacts_fax}
              />
            </div>
            <div className="Right">{page.contacts_fax}</div>
          </div>
          {/*  */}
          <div className="contacts">
            <div className="Left">
              <img
                className="site-contact-imgs"
                width="25"
                height="25"
                src={Email}
                alt={page.contacts_email}
                title={page.contacts_email}
              />
            </div>
            <div className="Right">
              <a href={`mailto:` + page.contacts_email}>
                {page.contacts_email}
              </a>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
