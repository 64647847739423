import React from "react";
import "./style.css";

export default function Analatics() {
  

  const sayHello = () => {


    document.cookie = "_ga=Empty; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
    document.cookie = "_ga_NC4FLBZHV5=Empty; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";


    sessionStorage.setItem("analatics", "no");
    window.location.reload();
  };
  const sayBye = () => {
    sessionStorage.setItem("analatics", "yes");
    window.location.reload();
  };

  const Close = () => {
    sessionStorage.setItem("popup", "no");
    window.location.reload();
  };

  const reOpen = () => {
    sessionStorage.setItem("popup", "yes");
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div
        className={
          sessionStorage.getItem("popup") === "no" ? "modal" : "modal active"
        }
        id="modal-id"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div className="h5">Datenschutzeinstellungen </div>
          </div>
          <div className="modal-body">
            <div className="content">
              <div className="Master">
                <div className="Left">Google Analatics</div>
                <div className="Right">
                  {sessionStorage.getItem("analatics") === "no" ? (
                    <button className="Btn Disable" onClick={sayBye}>
                      Disable
                    </button>
                  ) : (
                    <button className="Btn" onClick={sayHello}>
                      Active
                    </button>
                  )}
                </div>
              </div>
              <hr></hr>
              <small>
                Wir benötigen Ihre Zustimmung, bevor Sie unsere Website weiter
                nutzen können.
              </small>
              <small>
                Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu
                freiwilligen Diensten geben möchten, müssen Sie Ihre
                Erziehungsberechtigten um Erlaubnis bitten.
              </small>
              <small>
                Wir verwenden Cookies und andere Technologien auf unserer
                Website. Einige von ihnen sind unerlässlich, während andere uns
                helfen, diese Website und Ihre Erfahrung zu verbessern.
                Personenbezogene Daten können verarbeitet werden (z. B.
                IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte
                oder die Messung von Anzeigen und Inhalten. Weitere
                Informationen über die Verwendung Ihrer Daten finden Sie in
                unserer Datenschutzerklärung. Es besteht keine Verpflichtung,
                der Verarbeitung Ihrer Daten zuzustimmen, um dieses Angebot
                nutzen zu können. Sie können Ihre Auswahl jederzeit unter
                Präferenzen widerrufen oder ändern. Bitte beachten Sie, dass
                aufgrund individueller Einstellungen möglicherweise nicht alle
                Funktionen der Website verfügbar sind.
              </small>
              <hr></hr>
              <small>
                Einige Dienste verarbeiten personenbezogene Daten in den USA.
                Mit Ihrer Zustimmung zur Nutzung dieser Dienste erklären Sie
                sich auch mit der Verarbeitung Ihrer Daten in den USA gemäß Art.
                49 (1) lit. a DSGVO ein. Der EuGH stuft die USA als ein Land mit
                unzureichendem Datenschutz nach EU-Standards ein. Es besteht
                beispielsweise die Gefahr, dass US-Behörden personenbezogene
                Daten in Überwachungsprogrammen verarbeiten, ohne dass für
                Europäerinnen und Europäer eine Klagemöglichkeit besteht.
              </small>
              <div>
                {sessionStorage.getItem("popup") === "no" ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <button
                    style={{
                      marginTop: "15px",
                      background: "#2d2d2d",
                    }}
                    className="Btn"
                    onClick={Close}
                  >
                    X
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POPUP */}
      {sessionStorage.getItem("popup") === "no" ? (
        <div className="PopReopen">
          <button
            style={{
              fontSize: "14px",
            }}
            className="Btn"
            onClick={reOpen}
          >
            Datenschutzeinstellungen
          </button>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {/*  */}
    </React.Fragment>
  );
}
